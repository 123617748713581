.avatarImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: #0635c9 4px solid;
} 

.profileForm {
    width: 99%;
    direction: rtl;
    text-align: right;
    padding: 20;
}