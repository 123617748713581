.iframe-loading { 
    background:url(/src/assets/images/wloader.gif) center center no-repeat; 
    
}

.pageSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
}

