.cardBody{
    width: 100%;
    position: absolute;
    top: 50px;
    bottom: 0px;
    z-index: 100;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: #ffffff;
}

.cardStyle {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 10px; 
    justify-content: flex-start;
    align-items: flex-start; 
    direction: rtl;
    background-color: rgb(228, 225, 225);
    border-radius: 10px; 
    padding: 5px;
}

.innerCard { 
    width: 100%;
    padding: 10px; 
    text-align: right;
    border: 1px solid rgb(247, 132, 2);
    border-radius: 10px;  
}

.sticky {
    position: sticky !important;
    left: 0;
    top: 0;
    z-index: 4;
    height: 55px;
  }