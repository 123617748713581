.drpdown {
    padding: 4px;
    width: 160px;
    border-radius: 5px;
}

.textBox {
    padding: 5px;
    width: 250px;
}

.alertWidth {
    width: 200px;
}

.inputWidth {
    width: 200px;
    direction: rtl;
    color: black;
    font-size: 12pt;
  }