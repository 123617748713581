.msgeStyle {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 10px; 
    justify-content: flex-start; 
    align-items: flex-start; 
    direction: rtl;
    background-color: rgb(228, 225, 225);
    border-radius: 10px; 
    padding: 5px;
}