.mainHeader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 1002;
    background-color:rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 10;
    border-bottom: 1px gray solid;
}
.mainMarker {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 270px;
    z-index: 1000;
}
.modalMarker1 {
    position: fixed;
    top: 25%;
    left: 45%;
    width: 40px;
    height: 58px;
    background-color: transparent;
    z-index: 1000;
}
.modalMarker2 {
    position: fixed;
    top: 45%;
    left: 45%;
    width: 40px;
    height: 58px;
    background-color: transparent;
    z-index: 1000;
}
.panelBlank {
    width: '100%';
    height: 160px;
    background-color: transparent;
}
.panelContainer1 {
    flex: 1;
    display: flex;
    flex-direction: 'row';
    justify-content: center;
    align-items: 'center';
    align-self: 'center';
    padding-left: 5;
    background: transparent;
}
.panelContainer2 {
    flex: 1;
    display: flex;
    flex-direction: 'row';
    align-items: 'center';
    justify-content: center;
    align-self: 'center';
    padding-left: 5;
    background-color: #fff;
}
.panelContainer22 {
    flex: 1;
    display: flex;
    flex-direction: 'row';
    align-items: 'center';
    justify-content: center;
    align-self: 'center';
    padding-left: 5;
}
.wfooter {
    width: '100%';
    margin-right: 10;
}
.srvcIcon {
    flex: 1;
    width: 48px;
    height: 36px;
    resize: 'contain';
    margin: 5px;
}
.buttonStyle {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px; 
    justify-content: center;
    align-items: center; 
    border: 0px solid rgb(65, 64, 64);
    border-radius: 10px; 
    margin: 5px;
    background-color:rgb(255, 255, 255);
    -moz-box-shadow:    0px 1px 2px 0px #ccc;
    -webkit-box-shadow: 0px 1px 2px 0px #ccc;
    box-shadow:         0px 1px 2px 0px #ccc;
}
.buttonBlank {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px; 
    justify-content: center;
    align-items: center; 
    margin: 5px; 
}
.buttonStyle2 {
    display: flex;
    flex-direction: column;
    width: 115px;
    padding: 10px; 
    justify-content: center;
    align-items: center; 
    border-radius: 10px; 
    margin: 5px;
    background-color:rgb(255, 255, 255);
    -moz-box-shadow:    0px 1px 2px 0px #ccc;
    -webkit-box-shadow: 0px 1px 2px 0px #ccc;
    box-shadow:         0px 1px 2px 0px #ccc;
}
.buttonBlank2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px; 
    justify-content: center;
    align-items: center; 
    margin: 5px; 
}
.userAvatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: #fff 4px solid;
}

.modal-dialog {
    margin: 0 auto;
    width: 50vw;
    max-width: 300px;
    height: 180px;
}

.pageHeader {
    width: 100%;
    height: 55px;
    background-color: #0635c9;
    color: rgb(255, 255, 255);
}

.btnCredit {
    position: absolute;
    top: 60px;
    left: 20px;
    width: 120px;
    height: 30px;
    padding-top: 5px;
    font-size: 10;
    z-index: 1091;
    border-radius: 10px; 
    background-color: #0635c9;
    color: rgb(255, 255, 255);
}

.btnMyreq {
    position: absolute;
    top: 60px;
    right: 20px;
    width: 120px;
    height: 30px;
    padding-top: 5px;
    font-size: 10;
    z-index: 1092;
    border-radius: 10px; 
    background-color: #009688;
    color: rgb(255, 255, 255);
}

.addCredit {
    width: 100px;
    height: 30px;
    font-size: 10;
    padding: 4px;
    border-radius: 10px; 
    background-color: #0635c9;
    color: rgb(255, 255, 255);
}

.sellerImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: #0635c9 4px solid;
} 

.sellerIcon {
    width: 50px;
    height: 50px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 50%;
    border: #0635c9 2px solid;
} 

.locIcon {
    position: fixed;
    bottom: 5%;
    left: 88%;
    width: 30px;
    height: 30px;
    background-color: transparent;
    z-index: 1000;
    cursor: pointer;
}

.btnCall {
    width: 150px;
    height: 30px;
    margin: 2px;
    cursor: pointer;
    font-size: 16pt;
    text-align: center;
    vertical-align: middle;
    border-radius: 5px; 
    border: #888 1px solid;
    background-color: #0635c9;
  }

  .btnCallGray {
    width: 120px;
    height: 40px;
    margin: 2px;
    cursor: pointer;
    font-size: 16pt;
    text-align: center;
    vertical-align: middle;
    border-radius: 5px; 
    border: #888 1px solid;
    color: '#000';
    background-color: #d1d1d1;
    margin-bottom: 20px;
  }

  .btnCtlgu {
    width: 120px;
    height: 40px;
    margin: 2px;
    cursor: pointer;
    font-size: 16pt;
    text-align: center;
    vertical-align: middle;
    border-radius: 5px; 
    border: #888 1px solid;
    color: '#000';
    background-color: #ffffbb;
    margin-bottom: 20px;
  }