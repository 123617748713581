.bannerCard1 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 160px;
    border-radius: 10px;
    background-image: url('https://kalabisim.com/banner/banner001.jpg');
    background-size: cover;
    margin-bottom: 10px;
}

.bannerCard2 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 160px;
    border-radius: 10px;
    background-image: url('https://kalabisim.com/banner/banner002.jpg');
    background-size: cover;
    margin-bottom: 10px;
}

.bannerCard3 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 160px;
    border-radius: 10px;
    background-image: url('https://kalabisim.com/banner/banner003.jpg');
    background-size: cover;
    margin-bottom: 10px;
}

.bannerCard4 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 160px;
    border-radius: 10px;
    background-image: url('https://kalabisim.com/banner/banner004.jpg');
    background-size: cover;
    margin-bottom: 10px;
}

.bannerCard5 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 160px;
    border-radius: 10px;
    background-image: url('https://kalabisim.com/banner/banner005.jpg');
    background-size: cover;
    margin-bottom: 10px;
}

.bannerCard6 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 160px;
    border-radius: 10px;
    background-image: url('https://kalabisim.com/banner/banner006.jpg');
    background-size: cover;
    margin-bottom: 10px;
}

.bannerCard11 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 360px;
    height: 160px;
    margin: 5px;
    border-radius: 10px;
    background-image: url('https://kalabisim.com/banner/banner001.jpg');
    background-size: cover;
    margin-bottom: 10px;
}

.bannerCard22 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 360px;
    height: 160px;
    margin: 5px;
    border-radius: 10px;
    background-image: url('https://kalabisim.com/banner/banner002.jpg');
    background-size: cover;
    margin-bottom: 10px;
}

.bannerCard33 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 360px;
    height: 160px;
    margin: 5px;
    border-radius: 10px;
    background-image: url('https://kalabisim.com/banner/banner003.jpg');
    background-size: cover;
    margin-bottom: 10px;
}

.bannerCard44 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 360px;
    height: 160px;
    margin: 5px;
    border-radius: 10px;
    background-image: url('https://kalabisim.com/banner/banner004.jpg');
    background-size: cover;
    margin-bottom: 10px;
}

.bannerCard55 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 360px;
    height: 160px;
    margin: 5px;
    border-radius: 10px;
    background-image: url('https://kalabisim.com/banner/banner005.jpg');
    background-size: cover;
    margin-bottom: 10px;
}

.bannerCard66 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 360px;
    height: 160px;
    margin: 5px;
    border-radius: 10px;
    background-image: url('https://kalabisim.com/banner/banner006.jpg');
    background-size: cover;
    margin-bottom: 10px;
}

.scrollDir {
direction: ltr;
}

.mainRow {
    display: flex;    
    overflow-x: auto;
    /* flex-direction: row;
    -webkit-flex-direction: row;
    overflow-x: scroll;
    width: 100%;
    height: 100vh; */
  }
  
  .flexRow {
    display: flex;
    display: -webkit-flex;
  }


.cartImage{
    width: auto;
    height: 160px;
    z-index: 11;
}

.ribonView {
    width: 100%;
    opacity: 0.4;
    height: 40px;
    margin-bottom: 5px;
    background-color: gray;
    opacity: 0.4;
    z-index: 12;
}

.buttonCard {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 160px;
    border-radius: 10px;
    color: white; 
    font-size: 12; 
    margin: 10;
    z-index: 13;
}
