.sellerImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: #0635c9 4px solid;
} 

.modalOverlay {
    background-color: black;
    opacity: 0.8;
}